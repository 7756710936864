<template>
  <div class="flex column center" style="margin-bottom:4rem;">
    <div class="new_box">
      <div class="titlee">喜讯！热烈祝贺历思科技通过高新技术企业认定！</div>
      <p class="time">2021.03.25</p>

      <p class="space_content" style="text-indent:0">
        热烈祝贺厦门历思科技服务有限公司（以下简称“历思”）通过了“
        <span style="font-weight: 600;color: red">
          国家高新技术企业
        </span>
        ”认定，由此正式迈入国家高新技术企业行列。
      </p>

      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/39-1.png')">
      </div>

      <p class="space_content" style="text-indent:0">
        本次被评定为国家高新技术企业，将进一步推动公司自主创新、自主研发的进程，同时也是历思发展史上的又一个里程碑。
      </p>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/39-2.png')">
        <p class="center_">（历思物证科技产研体系架构图）</p>
      </div>

      <p class="space_content" style="text-indent:0">
        秉持“智慧物证，可信鉴定”的理念，历思长期致力于发展智慧物证科技，为社会提供可信鉴定服务。立足原发性创新，通过综合服务与配套软硬件产品的结合，历思在物证与鉴定领域建立了独特的竞争优势。
      </p>
      <p class="space_content" style="text-indent:0">
        今后，整个历思科技产研体系将持续创新，推动IT、刑侦等当代科技在物证与鉴定领域的融合、创新，以此获得竞争力与发展机遇。
      </p>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/39-3.png')">
      </div>


    </div>
    <div class="flex wrap .col442" style="width:100%;text-align:left;margin-top:1rem">
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex" style="margin-right:10%">
          <p style="white-space:nowrap">上一篇：</p>
          <router-link class="a" :to="'/news/lsnews/38'"><p class="ellipsis1">堃晟入选福建省污染地块调查评估、治理修复单位专业机构推荐名录</p>
          </router-link>
        </div>
      </div>
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex">
          <p style="white-space:nowrap">下一篇：</p> 无


        </div>
      </div>
      <div class="col2 flex  end1 center">
        <router-link class="a" style="color:#fff" :to="'/news/lsnews'">
          <el-button type="primary">返回列表</el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {}
    }
  },
}
</script>
<style>
.redText {
  background: red;
  color: white;
  font-size: 20px;
  font-weight: 600;
}

.titleText {
  font-size: 20px;
  font-weight: 600;
}
</style>
